import React, { useEffect, useState } from 'react'
import { translate } from '../../selectors/translations'
import { openModalWindow } from "../../store/ModalWindowData/action"
import { connect, useSelector } from "react-redux"
import { PatientProfileActionType } from "../../store/PatientProfile/types"
import { ModalWindowDataType } from "../../store/ModalWindowData/types"
import { authUser } from "../../selectors/auth"
import { getInstitutionsSelector, isGerasInstitution } from "../../selectors/institutions"
import SmsTemplates from "./smsTemplates"
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"
import { apiV2 } from "../../repositories/repository"
import PaymentsStatistic from "./paymentsStatistic"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import 'react-tabs/style/react-tabs.css'
import Institutions from "./Institutions/institutions"
import useTranslate from "../../hooks/useTranslate"
import TrackingEvents from "./trackingEvents"

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>

const AdminPanel: React.FC<PropsFromDispatch> = (props: PropsFromDispatch): React.ReactElement => {
  const [getPhoneListProgress, setGetPhoneListProgress] = useState(false)
  useSelector(getInstitutionsSelector)
  const user = useSelector(authUser)
  const translateLocalazy = useTranslate()


  useEffect(() => {
    if (getPhoneListProgress) {
      const endpoint = '/assist-phone-number'
      const token = window.localStorage.getItem('token')

      fetch(apiV2.request.config.BASE + endpoint, {
        headers: {
          'Response-Type': 'blob',
          'Authorization': 'Bearer ' + token,
        },
      })
        .then(response => response.text())
        .then((response) => {
          const encodedUri = encodeURI("data:text/csv;charset=utf-8," + response)
          const link = document.createElement("a")
          link.setAttribute("href", encodedUri)
          link.setAttribute("download", "Assistance.csv")
          document.body.appendChild(link)
          link.click()

          setGetPhoneListProgress(false)
        })
    }
  }, [getPhoneListProgress])

  return (
    <div>
      <Tabs forceRenderTabPanel={true}>
        <TabList>
          <Tab>{translate('Reports')}</Tab>
          {isGerasInstitution(user.institution) && <Tab>{translateLocalazy('admin.sms-templates.form-title')}</Tab>}
          {isGerasInstitution(user.institution) && <Tab>{translateLocalazy('admin.institution.form-title')}</Tab>}
          {isGerasInstitution(user.institution) && <Tab>Tracking Events</Tab>}
        </TabList>
        <TabPanel>
          <div className="card-box patient-info">
            <div className="flex flex-column pt-10">
              <div>
                <span style={{marginRight: '24px'}}>Get Phone Numbers to assist</span>
                <button onClick={(): void => setGetPhoneListProgress(true)}
                        className="g_btn g_btn--primary">{translate('Download file')}</button>
              </div>
            </div>
            {isGerasInstitution(user.institution) && <div className="flex flex-column pt-10">
                <div>
                    <span style={{marginRight: '24px'}}>Export patient answers data</span>
                    <button onClick={(): void => props.openModal('exportAnswersData', {})}
                            className="g_btn g_btn--primary">{translate('Download file')}</button>
                </div>
            </div>}
          </div>
          {/*<VoucherCodes/>*/}
          <PaymentsStatistic/>
        </TabPanel>
        {isGerasInstitution(user.institution) && <TabPanel><SmsTemplates/></TabPanel>}
        {isGerasInstitution(user.institution) && <TabPanel><Institutions/></TabPanel>}
        {isGerasInstitution(user.institution) && <TabPanel><TrackingEvents/></TabPanel>}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </Tabs>
    </div>
  )
}

const mapDispatchToProps = (dispatch: (action: PatientProfileActionType | ModalWindowDataType) => void) => ({
  openModal: (type: ModalWindowType, data: any): void => dispatch(openModalWindow(type, data)),
})
export default connect(() => ({}), mapDispatchToProps)(AdminPanel)

