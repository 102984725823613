import { Middleware } from 'redux'
import { TranslationsActionTypes, TranslationsActionType } from '../store/Translations/types'
import { AuthActionType, AuthActionTypes } from '../store/Auth/types'
import { attachTokenToRequests } from "../repositories/repository"

const localStorageEnchancer: Middleware = () =>
  (next) =>
    (action: TranslationsActionType | AuthActionType) => {
      switch (action.type) {
        case TranslationsActionTypes.SWITCH_TRANSLATION:
          window.localStorage.setItem('lang', action.language)
          break
        case AuthActionTypes.POST_LOGIN_SUCCESS:
          window.localStorage.setItem('token', action.response.accessToken)
          attachTokenToRequests()
          break
        case AuthActionTypes.CHECK_BANK_ID_STATUS_SUCCESS:
          if (action.response.accessToken) {
            window.localStorage.setItem('token', action.response.accessToken)
            attachTokenToRequests()
          }
          break
        case AuthActionTypes.LOGOUT:
          window.localStorage.removeItem('token')
          sessionStorage.removeItem('patient_filters')
          break
      }
      return next(action)
    }

export default localStorageEnchancer
