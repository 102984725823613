import React, { useState } from "react"
import { ReactComponent as MessageIcon } from '../../../../assets/img/icons/message.svg'
import { translate } from "../../../../selectors/translations"
import { postAskFamilySurvey } from "../../../../store/Family/action"
import { useDispatch, useSelector } from "react-redux"
import PhoneInputWithMask from "../../../../components/PhoneInputWithMask/PhoneInputWithMask"
import { institutionAccess } from "../../../../selectors/institutions"
import classNames from "classnames"
import useTranslate from "../../../../hooks/useTranslate"

interface IProps {
  patientId: string;
  institution: string;
}

const SymptomSurveyForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {patientId, institution} = props
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isValid, setValid] = useState(false)
  const dispatch = useDispatch()
  const translateLocalazy = useTranslate()
  const access = useSelector(institutionAccess)
  const available = access.sms

  const handleChange = (phone: string, data: any): void => {
    const rawNumber = phone.slice(data.dialCode.length)
    if (rawNumber.length && rawNumber[0] === '0') {
      setPhoneNumber(`${data.dialCode}${rawNumber.slice(1)}`)
      return
    }
    setPhoneNumber(phone)
    setValid(phone.length >= 9)
  }

  const handleSubmit = () => {
    if (!isValid) {
      return
    }
    if (!available) {
      return
    }
    const dto = {
      phoneNumber: `+${phoneNumber}`,
      patientId
    }
    dispatch(postAskFamilySurvey(dto))
  }

  const className = classNames([
    't_uppercase g_btn',
    {
      'feature-blocked': !available,
      'g_btn--disabled': !isValid,
      'g_btn--primary': isValid
    }
  ])

  return (
    <div className="card-box flex flex-column" style={{'overflow': 'initial'}}>
      <div className="box-title">
        <i className="icon-holder patients-detail_icon-holder"><MessageIcon/></i>
        <h3>{translate('Send symptom survey to relative')}</h3>
      </div>
      <div className="flex flex--wrap justify-between">
        <div className="item--12 item-md-7">
          {translate('requestFamilyPhoneLabel')}
          <PhoneInputWithMask
            institution={institution}
            inputValue={phoneNumber}
            onChange={handleChange}/>
        </div>
        <div>
          <div className="pt-20 pt-md-0">
            <button
              title={available ? undefined : translateLocalazy('feature-blocked')}
              onClick={handleSubmit}
              className={className}
              type="button">{translate('Send')}</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default SymptomSurveyForm
