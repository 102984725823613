import { all, put } from 'redux-saga/effects'
import { CreateInstitutionRequest, InstitutionEnum, UpdateInstitutionRequest } from "./types"
import {
  createInstitutionSuccess,
  getInstitutionsSuccess,
  getInstitutionStatisticSuccess,
  updateInstitutionSuccess
} from "./action"
import { bindHandler } from "../bind-handler"
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"

function* handleGetInstitutions() {
  const apiCall = apiV2.institution.getAllInstitutions.bind(apiV2.institution)
  const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(getInstitutionsSuccess(res.data))
  }
}

function* handleGetInstitutionsStatistic() {
  const apiCall = apiV2.institution.getStatistic.bind(apiV2.institution)
  const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(getInstitutionStatisticSuccess(res.data))
  }
}

function* handleCreateInstitution({payload}: CreateInstitutionRequest) {
  const apiCall = apiV2.institution.createInstitution.bind(apiV2.institution)
  const res = (yield wrapResponse(apiCall(payload))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(createInstitutionSuccess(res.data))
  }
}

function* handleUpdateInstitution({payload}: UpdateInstitutionRequest) {
  const apiCall = apiV2.institution.updateInstitution.bind(apiV2.institution)
  const res = (yield wrapResponse(apiCall(payload.id!, payload))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(updateInstitutionSuccess(res.data))
  }
}


export default function* institutionsSaga(): Generator {
  yield all([
    bindHandler(InstitutionEnum.GET_INSTITUTIONS_REQUEST, handleGetInstitutions),
    bindHandler(InstitutionEnum.GET_INSTITUTIONS_STATISTIC_REQUEST, handleGetInstitutionsStatistic),
    bindHandler(InstitutionEnum.CREATE_INSTITUTION_REQUEST, handleCreateInstitution),
    bindHandler(InstitutionEnum.UPDATE_INSTITUTION_REQUEST, handleUpdateInstitution),
  ])
}

