import { InstitutionEnum, InstitutionsActionType } from './types'
import { InstitutionDTO, InstitutionStatisticDTO, ManageInstitutionDTO } from "../../sdk"

export const getInstitutions = (): InstitutionsActionType => ({type: InstitutionEnum.GET_INSTITUTIONS_REQUEST})
export const getInstitutionsSuccess = (payload: InstitutionDTO[]): InstitutionsActionType => ({
  type: InstitutionEnum.GET_INSTITUTIONS_SUCCESS,
  payload
})

export const getInstitutionStatistic = (): InstitutionsActionType => ({type: InstitutionEnum.GET_INSTITUTIONS_STATISTIC_REQUEST})
export const getInstitutionStatisticSuccess = (payload: InstitutionStatisticDTO[]): InstitutionsActionType => ({
  type: InstitutionEnum.GET_INSTITUTIONS_STATISTIC_SUCCESS,
  payload
})

export const createInstitution = (payload: ManageInstitutionDTO): InstitutionsActionType => ({
  type: InstitutionEnum.CREATE_INSTITUTION_REQUEST,
  payload
})
export const createInstitutionSuccess = (payload: InstitutionDTO): InstitutionsActionType => ({
  type: InstitutionEnum.CREATE_INSTITUTION_SUCCESS,
  payload
})

export const updateInstitution = (payload: ManageInstitutionDTO): InstitutionsActionType => ({
  type: InstitutionEnum.UPDATE_INSTITUTION_REQUEST,
  payload
})
export const updateInstitutionSuccess = (payload: InstitutionDTO): InstitutionsActionType => ({
  type: InstitutionEnum.UPDATE_INSTITUTION_SUCCESS,
  payload
})
