import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close-icon.svg'
import { translate } from '../../selectors/translations'
import { ModalWindowAction, ModalWindowCallbackHandler } from '../../store/ModalWindowData/types'
import useTranslate from "../../hooks/useTranslate"
import { useSelector } from "react-redux"
import { getInstitutionsSelector } from "../../selectors/institutions"
import Select from 'react-select'
import { ManageInstitutionDTO, ManageSmsTemplateDto } from "../../sdk"

type PropsFromState = {
  onConfirm: (data: ModalWindowAction<any>) => void;
  data: any;
}

type FormActionState = {
  onConfirm: (val: any) => void;
  data?: any;
  onSubmitAction?: string;
}

const FormHeader: React.SFC<FormActionState> = (props: FormActionState): React.ReactElement => {
  const {onConfirm} = props
  return (
    <div className="note-popup_header flex collapse justify-betweenalign-center">
      <div className="flex-item flex-item--stretch">{translate('Add-voucher')}</div>
      <div className="flex-item flex-item--shrink">
        <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
        </button>
      </div>
    </div>
  )
}

const FormFooter: React.SFC<FormActionState> = (props: FormActionState): React.ReactElement => {
  const {onConfirm, data, onSubmitAction} = props
  return (
    <div className="note-popup_footer">
      <button className="g_btn g_btn--primary"
              onClick={(): void => onConfirm({action: onSubmitAction, data})}>{translate('save')}</button>
      <button className="g_btn g_btn--outlined"
              onClick={(): void => onConfirm({action: 'cancel', data: null})}>{translate('cancel')}</button>
    </div>
  )
}

export const EditVoucherCodeForm = (props: PropsFromState) => {
  const {data, onConfirm} = props

  const [discount, setDiscount] = useState(data.voucher.percentage || 50)
  const [code, setCode] = useState(data.voucher.generatedCode || '')

  return (
    <div>
      <FormHeader onConfirm={(val): void => onConfirm({action: val.action, data: {}})}/>

      <div className="input-holder flex-item item-sm-3"><label>{translate('Select-discount')}</label>
        <select
          onChange={(event) => {
            const target: number = +event.target.value
            setDiscount(target)
          }}
          value={discount} name="code">
          {data.discounts.map((el: any, i: number) => <option key={i}
                                                              value={el.percentage}>{el.percentage + '%'}</option>)}
        </select>
      </div>
      <div className="input-holder flex-item item-sm-6" style={{marginTop: '14px'}}>
        <label>{translate('Enter-code')}</label>
        <input type="text"
               onChange={(event) => {
                 const target = event.target.value
                 setCode(target.trim())
               }}
               value={code} name="voucherCode" placeholder={translate('Type here...')}/>
        {!code || code.length < 4 && <span
            className="login_form-error">{translate('Field must not be empty and must have a minimum of 4 characters long')}</span>}
      </div>
      <FormFooter onSubmitAction={'updateVoucherCode'} onConfirm={(val): void => onConfirm({
        action: val.action,
        data: {discount, code, id: data.voucher._id}
      })}/>
    </div>
  )
}

export const AddVoucherCodeForm = (props: PropsFromState): React.ReactElement => {
  const {data, onConfirm} = props

  const [discount, setDiscount] = useState(50)
  const [code, setCode] = useState('')

  return (
    <div>
      <FormHeader onConfirm={(val): void => onConfirm({action: val.action, data: {}})}/>
      <div className="input-holder flex-item item-sm-3"><label>{translate('Select-discount')}</label>
        <select
          onChange={(event) => {
            const target: number = +event.target.value
            setDiscount(target)
          }}
          value={discount} name="code">
          {data.discounts.map((el: any, i: number) => <option key={i}
                                                              value={el.percentage}>{el.percentage + '%'}</option>)}
        </select>
      </div>
      <div className="input-holder flex-item item-sm-6" style={{marginTop: '14px'}}>
        <label>{translate('Enter-code')}</label>
        <input type="text"
               onChange={(event) => {
                 const target = event.target.value
                 setCode(target.trim())
               }}
               value={code} name="voucherCode" placeholder={translate('Type here...')}/>
        {!code || code.length < 4 && <span
            className="login_form-error">{translate('Field must not be empty and must have a minimum of 4 characters long')}</span>}
      </div>
      <FormFooter onSubmitAction={'postVoucherCode'}
                  onConfirm={(val): void => onConfirm({action: val.action, data: {discount, code}})}/>
    </div>
  )
}

export const DeleteSmsTemplateConfirmationModal = (props: {
  onConfirm: ModalWindowCallbackHandler<'deleteSmsTemplate' | 'cancel'>
  data: { _id: string, titleKey: string };
}) => {
  const {data, onConfirm} = props
  const translateLocalazy = useTranslate()

  return (
    <div>
      <div className="note-popup_header flex collapse justify-betweenalign-center">
        <div
          className="flex-item flex-item--stretch"
          dangerouslySetInnerHTML={{__html: translateLocalazy('admin.sms-template.delete-template').replace('{templateName}', `<strong>"${translateLocalazy(data.titleKey)}"</strong>`)}}>
        </div>
        <div className="flex-item flex-item--shrink">
          <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
          </button>
        </div>
      </div>
      <div className="note-popup_footer">
        <button className="g_btn g_btn--primary"
                onClick={(): void => onConfirm({
                  action: 'deleteSmsTemplate',
                  data: data._id
                })}>{translateLocalazy('delete')}</button>
        <button className="g_btn g_btn--outlined"
                onClick={(): void => onConfirm({action: 'cancel', data: null})}>{translate('cancel')}</button>
      </div>
    </div>
  )
}

export const ManageSmsTemplateModal = (props: {
  onConfirm: ModalWindowCallbackHandler<'postSmsTemplate' | 'cancel'>;
  data: ManageSmsTemplateDto | null;
}) => {
  const {data, onConfirm} = props
  const translateLocalazy = useTranslate()
  const institutions = useSelector(getInstitutionsSelector)

  const [title, setTitle] = useState(data?.title || '')
  const [selectedInstitutions, setSelectedInstitutions] = useState(data?.visibleForInstitutions || [])

  return (
    <div>
      <div className="note-popup_header flex collapse justify-betweenalign-center">
        <div className="flex-item flex-item--stretch">{translateLocalazy('admin.sms-template.new-template')}</div>
        <div className="flex-item flex-item--shrink">
          <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
          </button>
        </div>
      </div>
      <div className="input-holder flex-item item-sm-6" style={{marginTop: '14px'}}>
        {!data?._id &&
            <div className="input-holder flex-item">
                <label>{translateLocalazy('admin.sms-template.enter-title')}</label>
                <input type="text"
                       onChange={(event) => setTitle(event.target.value)}
                       value={title} name="title" placeholder={translate('Type here...')}/>
              {!title && <span
                  className="login_form-error">{translate('Field must not be empty and must have a minimum of 4 characters long')}</span>}
            </div>
        }
        <br/>
        <div className="input-holder flex-item">
          <label>{translateLocalazy('admin.sms-template.select-institution')}</label>
          <Select
            name="institutions"
            isMulti
            defaultValue={selectedInstitutions.map(e => ({value: e, label: institutions.find(i => i.id === e)?.name}))}
            options={institutions.map(e => ({value: e.id, label: e.name}))}
            onChange={e => setSelectedInstitutions(e.map(a => a.value))}
          />
        </div>
      </div>
      <FormFooter onSubmitAction={'postSmsTemplate'} onConfirm={() => {
        const manageDto: ManageSmsTemplateDto = {
          _id: data?._id,
          title: title.trim(),
          visibleForInstitutions: selectedInstitutions
        }
        onConfirm({action: 'postSmsTemplate', data: manageDto})
      }}/>
    </div>
  )
}

export const ManageInstitutionModal = (props: {
  onConfirm: ModalWindowCallbackHandler<'createInstitution' | 'updateInstitution' | 'cancel'>;
  data: ManageInstitutionDTO | null;
}) => {
  const {data, onConfirm} = props

  const [name, setName] = useState(data?.name || '')
  const [smsName, setSmsName] = useState(data?.smsName || '')
  const [oldStyleSsPdf, setOldStyleSsPdf] = useState(data?.oldStyleSsPdf ?? false)
  const [sms, setSms] = useState<boolean>(data?.access?.sms ?? true)
  const [symptomSurveyTest, setSymptomSurveyTest] = useState<boolean>(data?.access?.symptomSurveyTest ?? true)
  const [anamnesisTest, setAnamnesisTest] = useState<boolean>(data?.access?.anamnesisTest ?? true)
  const [cognitiveTest, setCognitiveTest] = useState<boolean>(data?.access?.cognitiveTest ?? true)
  const [medstaffManagement, setMedstaffManagement] = useState<boolean>(data?.access?.medstaffManagement ?? true)
  const [deletingPatients, setDeletingPatients] = useState<boolean>(data?.access?.deletingPatients ?? true)

  return (
    <div>
      <div className="note-popup_header flex collapse justify-betweenalign-center">
        <div className="flex-item flex-item--stretch">{data?.id ? 'Change institution' : 'New institution'}</div>
        <div className="flex-item flex-item--shrink">
          <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
          </button>
        </div>
      </div>
      <div className="input-holder flex-item item-sm-6" style={{marginTop: '14px'}}>
        <div className="input-holder flex-item">
          <label>Name</label>
          <input type="text"
                 onChange={(event) => setName(event.target.value)}
                 value={name} name="title" placeholder="Name"/>
        </div>

        <div className="input-holder flex-item">
          <label>Sms name</label>
          <input type="text"
                 onChange={(event) => setSmsName(event.target.value)}
                 value={smsName} name="title" placeholder="Sms name"/>
        </div>

        <div className="input-holder flex-item pt-20">
          <div className="checkbox-wrapper-2">
            <input type="checkbox" checked={oldStyleSsPdf}
                   onChange={(event) => setOldStyleSsPdf(event.target.checked)}/>
            <span style={{fontSize: 12, paddingLeft: 10}}>Old style ss pdf</span>
          </div>
        </div>

        <div className="input-holder flex-item">
          <div className="checkbox-wrapper-2">
            <input type="checkbox" checked={symptomSurveyTest}
                   onChange={(event) => setSymptomSurveyTest(event.target.checked)}/>
            <span style={{fontSize: 12, paddingLeft: 10}}>Symptom survey</span>
          </div>
          <div className="checkbox-wrapper-2">
            <input type="checkbox" checked={cognitiveTest}
                   onChange={(event) => setCognitiveTest(event.target.checked)}/>
            <span style={{fontSize: 12, paddingLeft: 10}}>Cognitive</span>
          </div>
          <div className="checkbox-wrapper-2">
            <input type="checkbox" checked={anamnesisTest}
                   onChange={(event) => setAnamnesisTest(event.target.checked)}/>
            <span style={{fontSize: 12, paddingLeft: 10}}>Anamnesis</span>
          </div>
          <div className="checkbox-wrapper-2">
            <input type="checkbox" checked={sms}
                   onChange={(event) => setSms(event.target.checked)}/>
            <span style={{fontSize: 12, paddingLeft: 10}}>Sms</span>
          </div>
          <div className="checkbox-wrapper-2">
            <input type="checkbox" checked={medstaffManagement}
                   onChange={(event) => setMedstaffManagement(event.target.checked)}/>
            <span style={{fontSize: 12, paddingLeft: 10}}>Medstaff management</span>
          </div>
          <div className="checkbox-wrapper-2">
            <input type="checkbox" checked={deletingPatients}
                   onChange={(event) => setDeletingPatients(event.target.checked)}/>
            <span style={{fontSize: 12, paddingLeft: 10}}>Deleting patients</span>
          </div>
        </div>
        <br/>
      </div>
      <FormFooter onSubmitAction={'createInstitution'} onConfirm={() => {
        const manageDto: ManageInstitutionDTO = {
          id: data?.id,
          name: name.trim(),
          smsName: smsName.trim(),
          oldStyleSsPdf,
          access: {
            cognitiveTest,
            symptomSurveyTest,
            anamnesisTest,
            sms,
            medstaffManagement,
            deletingPatients
          }
        }

        if (data?.id) {
          onConfirm({action: 'updateInstitution', data: manageDto})
        } else {
          onConfirm({action: 'createInstitution', data: manageDto})
        }
      }}/>
    </div>
  )
}
