import React from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'

import AccountForm from '../../components/AccountForm/AccountForm'

import { AccountsActionType } from '../../store/Accounts/types'
import { getAccount, unsetAccountInfo, updateRequest } from '../../store/Accounts/action'
import { ApplicationState } from '../../store'
import { AccountFormMode } from "./constants"

import '../../assets/scss/layouts/greenHills.scss'
import { InstitutionDTO, MedStaffDto, MedStaffRole } from "../../sdk"

type PropsFromState = {
  accountInfo: MedStaffDto;
  authUser: MedStaffDto;
  institutions: InstitutionDTO[]
}

type PropsFromDispatch = {
  updateRequest: (data: MedStaffDto) => void;
  getAccountInfo: (id: string) => void;
  unsetAccountInfo: () => void;
}

type RouteParams = {
  id: string | undefined;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<RouteParams>;

type MedPersonalNumber = {
  medPersonalNumber: string;
}

type AccountFormData = MedStaffDto & MedPersonalNumber;

class AccountUpdatePage extends React.Component<AllProps> {
  constructor(state: AllProps) {
    super(state);
    this.saveAccountInfo = this.saveAccountInfo.bind(this);
  }

  componentDidMount(): void {
    if (this.props.match.params.id) {
      this.props.getAccountInfo(this.props.match.params.id);
    }
  }

  componentWillUnmount(): void {
    this.props.unsetAccountInfo();
  }

  saveAccountInfo(accountData: AccountFormData): void {
    const { roles } = this.props.accountInfo;
    if (roles.includes(MedStaffRole.ADMIN)) {
      accountData.roles = roles;
    } else {
      accountData.roles = Array.isArray(accountData.roles) ? accountData.roles : [accountData.roles];
    }
    this.props.updateRequest({ ...accountData, personalNumber: accountData.medPersonalNumber });
    this.props.history.push('/accounts')
  }

  render(): React.ReactElement {
    const { accountInfo } = this.props;
    const { firstName, lastName, email, institution, gender, personalNumber, country, language, _id, roles } = accountInfo;

    return (
      <AccountForm
        data={{ firstName, lastName, email, roles: roles[0], medPersonalNumber: personalNumber, institution, gender, country, language, _id }}
        mode={AccountFormMode.EditAccount}
        onCancel={(): void => this.props.history.push('/accounts')}
        onSubmit={this.saveAccountInfo}>
      </AccountForm>
    )
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  accountInfo: state.accounts.accountInfo,
  authUser: state.auth.user,
  institutions: state.institutions.all
})

const mapDispatchToProps = (dispatch: (action: AccountsActionType) => void): PropsFromDispatch => ({
  updateRequest: (data: MedStaffDto): void => dispatch(updateRequest(data)),
  getAccountInfo: (id: string): void => dispatch(getAccount(id)),
  unsetAccountInfo: (): void => dispatch(unsetAccountInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountUpdatePage);

