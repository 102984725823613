import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux'

import { AccountsActionType, AccountFilters, AccountAction } from '../../store/Accounts/types';
import { fetchRequest, filterChanged, deletedAccountRequest } from '../../store/Accounts/action';
import { ApplicationState } from '../../store';
import { getAccounts } from '../../selectors/accounts';
import { translate } from '../../selectors/translations';

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search-icon.svg';

import { TableHeaders, AccountsTableBody } from '../../components/DataTable/Table';
import { openModalWindow } from '../../store/ModalWindowData/action';
import { ModalWindowDataType } from '../../store/ModalWindowData/types';

import greenHills from '../../assets/img/greenHills.svg';
import '../../assets/scss/layouts/greenHills.scss'
import { InstitutionAccess, MedStaffDto } from "../../sdk"
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"
import { institutionAccess } from "../../selectors/institutions"

const sortingFields = ['role', 'lastName', 'firstName'];

interface PropsFromState {
  authUser: MedStaffDto;
  deletedAccounts: MedStaffDto[];
  filters: AccountFilters;
  successMsg?: string;
  errorMsg?: string;
  access: InstitutionAccess;
}

type PropsFromDispatch  = ReturnType<typeof mapDispatchToProps>

type AllProps = PropsFromDispatch & PropsFromState & RouteComponentProps;

class AccountsListClass extends React.Component<AllProps> {
  actionTypes: {[key: string]: (data: string) => void} = {
    'restore': (): void => {},
    'delete': (): void => {}
  }

  constructor(props: AllProps) {
    super(props);
    this.actionTypes.restore = ((id: string): void => {
      const patient = this.props.deletedAccounts.find((a) => a._id === id)!;
      this.props.openModal('restore', {_id: patient._id, firstName: patient.firstName, lastName: patient.lastName, type: 'restoreAccount'})
    }).bind(this);
    this.actionTypes.delete = ((id: string): void => {
      const patient = this.props.deletedAccounts.find((a) => a._id === id)!;
      this.props.openModal('delete', {_id: patient._id, firstName: patient.firstName, lastName: patient.lastName, type: 'deleteAccount'})
    }).bind(this);
  }

  public componentDidMount(): void {
    this.props.getDeletedList();
  }

  render(): React.ReactElement {
    const { filters, filterChanged, deletedAccounts, authUser, access } = this.props;
    const availableActions: string[] = []
    if (access.medstaffManagement) {
      availableActions.push('restore')
      availableActions.push('delete')
    }
    return (
      <div>
        {/* FILTERS BLOCK */}
        <div className="search-box">
          <div className="flex-container justify-between align-start">
            <div className="flex-item item-sm-12 item-lg-7">
              <div className="search-block_wrapper flex">
                <div className="input-holder flex-item--stretch">
                  <div className="icon-holder">
                    <SearchIcon className="search-block_search-icon" />
                  </div>
                  <input
                    className="search-block_input"
                    onChange={(e): void => filterChanged({ searchTerm: e.target.value })}
                    type="text"
                    placeholder={translate('account.search.placeholder')} />
                </div>
              </div>
              <div className="search-block_results-counter hidden-lg-down">
                <span>{translate('search.results')}:&nbsp;</span>
                <span className="t_bold">{deletedAccounts.length|| 0}&nbsp;</span>
                <span>{translate('accounts')}</span>
              </div>
            </div>
            <div className="search_filters-row flex-item item-sm-12 item-lg-5">
            </div>
          </div>
        </div>

        {/* TABLE BLOCK */}
        <div className="search_table-box">
          <table className="search_patients-table">
            <TableHeaders
              onSortClick={filterChanged}
              columnTitles={['lastName', 'firstName', 'gender', 'institution', 'role']}
              sortOpts={{
                sortableColumns: sortingFields,
                isAsc: filters.sort.direction,
                filterField: filters.sort.field
              }}
            />
            <AccountsTableBody
              data={deletedAccounts}
              actions={availableActions}
              propKeys={['lastName', 'firstName', 'gender', 'institution', 'country']}
              onActionClick={(data: AccountAction): void => this.actionTypes[data.action](data._id)}
              authUser={authUser}
            />
          </table>
        </div>
        <img className="greenHills" src={greenHills}/>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  authUser: state.auth.user,
  deletedAccounts: getAccounts(state),
  filters: state.accounts.filters,
  successMsg: '',
  errorMsg: '',
  access: institutionAccess(state),
});

const mapDispatchToProps = (dispatch: (action: AccountsActionType| ModalWindowDataType) => void) => ({
  fetchRequest: (): void => dispatch(fetchRequest()),
  filterChanged: (data): void => dispatch(filterChanged(data)),
  getDeletedList: (): void => dispatch(deletedAccountRequest()),
  openModal: (type: ModalWindowType, data: any): void => dispatch(openModalWindow(type, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsListClass);

