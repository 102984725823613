import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import Toggleable from '../../rc-components/Toggleable'
import { DropdownCb } from '../../types/Common'
import { formatToDate, formatDate } from '../../utils/datetime'

import { ReactComponent as InfoIcon } from "../../assets/img/icons/information-outline.svg"
import { ReactComponent as BrainProfil } from '../../assets/img/brain-profil.svg'
import { ReactComponent as BrainTop } from '../../assets/img/brain-top.svg'
import QuestionCard from './QuestionsCard'
import MemoryCategory from './MemoryCategory'
import SideBrainSwitcher from './SideBrainSwitcher'
import { getFamily, getFamilySurveyAnswerData } from '../../selectors/patientProfile'
import { BrainConfigItem } from '../../store/Patients/types'
import { translate } from '../../selectors/translations'
import { ModalWindowDataType } from '../../store/ModalWindowData/types'
import { openModalWindow } from '../../store/ModalWindowData/action'
import {
  getBrainConfig,
  symptomSurveyScoreConfigs,
  mCategory,
  fCategory,
  ptCategory,
  skCategory,
  asCategory, surveyCharts2
} from '../../selectors/survey'
import { ApplicationState } from '../../store'
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check.svg'
import { setTestAsViewed } from '../../store/PatientProfile/action'
import { STATUS_COLOR } from '../../types/constants'
import {
  calculateActualMaxSymptomSurveyScore,
  calculateSymptomSurveyScore
} from "../../utils/calculateSymptomSurveyScore"
import { getAnswerClass } from "../../utils/getAnswerClass"
import FamilyProgressLine from "./FamilyProgressLine"
import useTranslate from "../../hooks/useTranslate"
import {
  QuestionerAnswersDTOOutput,
  QuestionerDTO,
  QuestionScreenMetadata,
  RadioGroupInputMetadata,
  UserDto
} from "../../sdk"
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"
import { isDefined } from "../../sdk/core/request"


type PropsFromParent = {
  answers: QuestionerAnswersDTOOutput
  patient: Pick<UserDto, 'firstName' | 'lastName'>
}

type PropsFromState = {
  surveyConfigs: BrainConfigItem[];
}

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>

type All = PropsFromParent & PropsFromDispatch & PropsFromState;

export const getRelativeDegree = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput) => {
  const relativeQuestion = findScreenBySlug(questioner, 'how-related-with-patienteny')
  return answers.answers.find(e => e.screen === relativeQuestion?.id)?.value
}
export const getAnswersForSection = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput, section: number) =>
  questioner.sections[section].screens
    .map(sectionScreen => answers.answers
      .find(answer => answer.screen === sectionScreen.screen.id)).filter(isDefined)

const findScreenBySlug = (questioner: QuestionerDTO, slug: string) => questioner.sections[5].screens.find(e => e.screen.slug === slug)?.screen

export const getSymptomsDevelopChartPicture = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput) => {
  const symptomsManifestationsQuestion = findScreenBySlug(questioner, 'way-symptoms-develop')
  const symptomsManifestationsAnswer = answers.answers.find(e => e.screen === symptomsManifestationsQuestion?.id)
  return surveyCharts2[symptomsManifestationsAnswer?.value]
}

export const getDementiaDuration = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput) => {
  let dementiaDuration: string[] = []
  const dementiaQuestion = findScreenBySlug(questioner, 'first-symptoms-noticed')
  const dementiaDurationAnswer = answers.answers.find(e => e.screen === dementiaQuestion?.id)
  const dementiaAnswerNotRelevant = dementiaDurationAnswer?.value === 'do-not-know-not-relevant'

  if (!dementiaAnswerNotRelevant) {
    const possibleValues = ((dementiaQuestion?.metadata as QuestionScreenMetadata).input.metadata as RadioGroupInputMetadata).values
      .filter(e => e.value !== 'do-not-know-not-relevant')
    const answerIndex = possibleValues.findIndex(e => e.value === dementiaDurationAnswer?.value)
    dementiaDuration = possibleValues.map((e, i) => dementiaDurationAnswer && i <= answerIndex ? 'active' : '')
  }

  return {dementiaDuration, dementiaAnswerNotRelevant}
}

const FamilySurveyCard = (props: All) => {
  const translateLocalazy = useTranslate()
  const dispatch = useDispatch()
  const [selectedProfile, setSelectedProfile] = useState('0')
  const {patient, surveyConfigs, answers} = props
  const questioner = answers.questioner
  const patientName = patient.firstName + ' ' + patient.lastName
  const status = 'Complete'
  const maxScorePossible = symptomSurveyScoreConfigs.maxScoreAnswerValue * (symptomSurveyScoreConfigs.endIndex + 1)
  const parietal = getAnswersForSection(questioner, answers, 0)
  const frontal = getAnswersForSection(questioner, answers, 1)
  const subcortical = getAnswersForSection(questioner, answers, 2)
  const memory = getAnswersForSection(questioner, answers, 3)
  const associatedSymptoms = getAnswersForSection(questioner, answers, 4)
  const relativeDegree = translateLocalazy(getRelativeDegree(questioner, answers))

  const chart = getSymptomsDevelopChartPicture(questioner, answers)
  const {dementiaDuration, dementiaAnswerNotRelevant} = getDementiaDuration(questioner, answers)

  const onProfileChange = (val: string): void => {
    setSelectedProfile(val)
  }

  const maxScore = calculateActualMaxSymptomSurveyScore([
    ...parietal,
    ...frontal,
    ...subcortical,
    ...memory,
    ...associatedSymptoms,
  ])
  const parietalCurrent = calculateSymptomSurveyScore(parietal)
  const frontalCurrent = calculateSymptomSurveyScore(frontal)
  const subcorticalCurrent = calculateSymptomSurveyScore(subcortical)
  const memoryCurrent = calculateSymptomSurveyScore(memory)
  const associatedSymptomsCurrent = calculateSymptomSurveyScore(associatedSymptoms)
  const currentScore = calculateSymptomSurveyScore([
    ...parietal,
    ...frontal,
    ...subcortical,
    ...memory,
    ...associatedSymptoms,
  ])
  const frontalTotalNormalized = calculateActualMaxSymptomSurveyScore(frontal)
  const parietalTotalNormalized = calculateActualMaxSymptomSurveyScore(parietal)
  const subcorticalTotalNormalized = calculateActualMaxSymptomSurveyScore(subcortical)
  const memoryTotalNormalized = calculateActualMaxSymptomSurveyScore(memory)
  const associatedSymptomsTotalNormalized = calculateActualMaxSymptomSurveyScore(associatedSymptoms)
  const surveyAnswers = getFamilySurveyAnswerData(answers.answers, questioner)
  const questions = getFamily(`${patient.firstName} ${patient.lastName}`, questioner)

  const renderSymptomResultsInfo = (slug: string, showInfoIcon = true) => {
    return <div className="flex-item flex-item--stretch" key={slug}>
      {
        showInfoIcon ?
          <div className="risk_progress-legend flex align-center">
            <div className="common-title">{translateLocalazy(`symptom-survey.results.${slug}`)}</div>
            <div className="flex-item--shrink">
              <InfoIcon className={`info-icon`}/>
            </div>
            <div className={`info_list survey-info-popup ${slug === "first-noticed-symptoms" ? 'transform-0' : ''}`}>
              <div className="flex justify-center align-center">
                {translateLocalazy(questions[slug]).replaceAll('<strong>', '').replaceAll('</strong>', '')}
              </div>
            </div>
          </div> : <div className="common-title">{translateLocalazy(`symptom-survey.results.${slug}`)}</div>
      }
      <div className="info-body">{translateLocalazy(surveyAnswers[slug])}</div>
    </div>
  }

  return (
    <div className="flex-item item-sm-12">
      <div className="card-box diagnostic_test-box" style={{overflow: 'visible'}}>
        <Toggleable>
          {
            (dropdownId: string, setDropdownId: DropdownCb): React.ReactElement => (
              <div>
                <div className="diagnostic_test_summary">
                  <div
                    onClick={(): void => {
                      setDropdownId(dropdownId === 'FamilySurveyCard' ? '' : 'FamilySurveyCard')
                      dispatch(setTestAsViewed({testId: answers.id!, sectionId: 0}))
                    }}>
                    <div className="flex-container justify-between">
                      <div className="flex-item flex-item--shrink">
                        <div className="test-title">{translate('symptom survey')}
                          <span style={{fontStyle: 'italic'}}>
                            {` - ${answers.oneTimeRespondent?.firstName ?? ''} 
                            ${answers.oneTimeRespondent?.lastName ?? ''} 
                            ${relativeDegree ? ' (' + translate(relativeDegree) + ')' : ''}`}
                          </span>
                        </div>
                      </div>
                      <div className={`flex-item flex-item--shrink`}>
                        <div className={`status_box`}>
                          <div className="status-holder">
                            {
                              status === 'Complete' &&
                                <CheckIcon className="circle-checked"/>
                            }
                            <span
                              className="status-text">{status === 'Complete' ? translateLocalazy('questioner-last-update') + ': ' + formatDate(answers.updatedAt!) : translate(status)}</span>
                            <span className={`circle circle--${STATUS_COLOR[status]}`}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-container card-header-results">
                      <div className="flex-item flex-item--shrink card-header-results-block">
                        <div
                          className="common-title card-header-results-header m-0">{translate('Duration in years')}</div>
                        {!dementiaAnswerNotRelevant &&
                            <div className="card-header-duration_box">
                                <ul className="dementia_sections-list">
                                  {dementiaDuration.map((item, index) => {
                                    return <li key={`dementia-${index}`} className={item}>&nbsp;</li>
                                  })
                                  }
                                </ul>
                                <ul className="dementia_values-list">
                                    <li>&lt;1/2</li>
                                    <li>1/2-1</li>
                                    <li>1-2</li>
                                    <li>2-3</li>
                                    <li>3-5</li>
                                    <li>&gt;5</li>
                                </ul>
                            </div>
                        }
                        {dementiaAnswerNotRelevant &&
                            <div className="card-header-duration_box">
                                <p className="m-0">{translate('answered_as_not_relevant')}</p>
                            </div>
                        }
                      </div>
                      <div className="flex-item flex-item--shrink card-header-results-block">
                        <FamilyProgressLine
                          infoLegendKey={'symptom_survey.riskLegend.hint'}
                          legend={`${translate('Points')}: <strong>${currentScore}</strong> / <strong>${maxScore}</strong> (${translate('max_if_all_answered')}. ${maxScorePossible})`}
                          progressValue={currentScore}
                          maxIntervalValue={maxScore}
                        />
                      </div>
                      <div
                        className="flex flex-column flex-item align-center flex-item--shrink card-header-results-block justify-center">
                        <div className="common-title m-0">{translate('Course')}</div>
                        <img className="course-сhart_image" src={chart} alt="chart-picture"/>
                      </div>
                    </div>
                  </div>
                </div>
                {dropdownId &&
                    <div className="diagnostic_test_body">
                        <section className="test_general-info_box">
                            <div className="flex-container pb-10">
                                <div className="flex-item flex-item--stretch">
                                    <div className="common-title">{translate('Answered by')}</div>
                                    <div className="info-body">{`${answers.oneTimeRespondent?.firstName ?? ''}
                           ${answers.oneTimeRespondent?.lastName ?? ''}`}</div>
                                </div>

                                <div className="flex-item flex-item--stretch">
                                    <div className="common-title">{translate('Relationship')}</div>
                                    <div className="info-body">{translate(relativeDegree)}</div>
                                </div>

                                <div className="flex-item flex-item--stretch">
                                    <div className="common-title">{translate('Date answered')}</div>
                                    <div className="info-body">{formatToDate(answers.createdAt!)}</div>
                                </div>

                                <div className="flex-item flex-item--stretch">
                                    <div className="common-title">{translate('phone')}:</div>
                                    <div className="info-body">{answers.oneTimeRespondent?.phoneNumber || '-'}</div>
                                </div>
                              {
                                renderSymptomResultsInfo("patienten-agree-with-symptom-survey")
                              }
                            </div>
                            <div className="flex-container pb-10">
                              {
                                [
                                  "first-noticed-symptoms",
                                  "most-noticeable-symptoms",
                                  "hard-to-manage-symptoms",
                                  "evident-specific-event-symptoms",
                                  "stress-for-the-family"
                                ].map((el) => renderSymptomResultsInfo(el))
                              }
                            </div>
                            <div className="flex-container">
                              {
                                renderSymptomResultsInfo("space-for-comments", false)
                              }
                            </div>
                        </section>
                        <section className="pt-20">
                            <div
                                className="test_description_title">{translate('Regional Dysfunktionsprofil Patient')}</div>
                            <div className="flex-container disfunctions_analize">
                                <div className="flex-item item-sm-7">
                                    <div
                                        className="flex justify-center flex-column align-center frontal-pt brain-category">
                                        <div className="t_center">
                                            <div className="test_description_title">{translate('Frontal')}</div>
                                            <div className="title-points">
                                              {translate('Points')}: <strong>{frontalCurrent} / {frontalTotalNormalized}</strong>
                                            </div>
                                        </div>
                                        <div className="t_center">
                                            <div
                                                className="test_description_title">{translate('Parietal / Temporal')}</div>
                                            <div className="title-points">
                                              {translate('Points')}: <strong>{parietalCurrent} / {parietalTotalNormalized}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="brain_map_box">
                                        <div className="brain_layer brain_layer--profil">
                                            <div className="additional-wrapper">
                                                <BrainProfil className="BrainProfil"/>

                                                <div className="brain_points-list">

                                                  {frontal.map((a, i) => (
                                                    <div
                                                      className={`brain_point-item f${i} ${getAnswerClass(a.value)}`}
                                                      key={`f${i}`}
                                                      data-content={translate(fCategory[i].title)}>
                                                      <span>{`F${i + 1}`}</span>
                                                    </div>
                                                  ))}
                                                  {parietal.map((a, i) => (
                                                    <div
                                                      className={`brain_point-item pt${i} ${getAnswerClass(a.value)}`}
                                                      key={`pt${i}`}
                                                      data-content={translate(ptCategory[i].title)}>
                                                      <span>{`PT${i + 1}`}</span>
                                                    </div>
                                                  ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="drawing-line" style={{marginTop: '40px', marginBottom: '40px'}}/>
                                    <div className="flex justify-center flex-column align-center brain-category">
                                        <div className="test_description_title">{translate('Subcortical')}</div>
                                        <div
                                            className="title-points">{translate('Points')}: <strong>{subcorticalCurrent} / {subcorticalTotalNormalized}</strong>
                                        </div>
                                    </div>
                                    <div className="brain_map_box">
                                        <div className="brain_layer brain_layer--top">
                                            <BrainTop/>
                                            <ul className="brain_points-list">
                                              {subcortical.map((a, i) => (
                                                <div
                                                  className={`brain_point-item sk${i} ${getAnswerClass(a.value)}`}
                                                  key={i}
                                                  data-content={translate(skCategory[i].title)}>
                                                  <span>{`SK${i + 1}`}</span>
                                                </div>
                                              ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <hr className="drawing-line" style={{marginTop: '40px', marginBottom: '40px'}}/>
                                    <div className="flex justify-center flex-column align-center brain-category">
                                        <div className="test_description_title">{translate('Memory')}</div>
                                        <div
                                            className="title-points">{translate('Points')}: <strong>{memoryCurrent} / {memoryTotalNormalized}</strong>
                                        </div>
                                    </div>
                                    <div className="flex justify-center brain-category">
                                        <div className="thermometer_box">
                                          {
                                            memory
                                              .map((v, i: number) => ({...v, index: i + 1}))
                                              .map((v, i: number) => (
                                                <div key={i} className="thermometer_item">
                                                  <div
                                                    className={`content thermometer_item_cell ${getAnswerClass(v.value)}`}
                                                    data-content={translate(mCategory[i].title)}></div>
                                                  <div
                                                    className="thermometer_item_title arrow_box">{`M${v.index}`}</div>
                                                </div>
                                              ))
                                          }
                                        </div>
                                    </div>
                                </div>
                                <SideBrainSwitcher
                                    onProfileChange={onProfileChange}
                                    selectedProfile={surveyConfigs[parseInt(selectedProfile)]}
                                    list={surveyConfigs}/>
                            </div>
                            <div className="flex-container justify-center align-start disfunctions_legend">
                                <div className="flex-item flex-item--shrink">
                                    <div className="legend-item red"><span>{translate('p-1')}</span></div>
                                </div>
                                <div className="flex-item flex-item--shrink">
                                    <div className="legend-item yellow"><span>{translate('p-2')}</span></div>
                                </div>
                                <div className="flex-item flex-item--shrink">
                                    <div className="legend-item green"><span>{translate('p-3')}</span></div>
                                </div>
                                <div className="flex-item flex-item--shrink">
                                    <div className="legend-item white"><span>{translate('p-4')}</span></div>
                                </div>
                            </div>
                            <div className="diagnostic_test_body">
                                <div className="exercise_category">
                                    <MemoryCategory
                                        title={translate('Frontal')}
                                        profileTitle={surveyConfigs[parseInt(selectedProfile)].title}
                                        patientProfile={frontal}
                                        score={frontalCurrent}
                                        totalScore={frontalTotalNormalized}
                                        illnessProfile={fCategory.map((v, i) => ({
                                          ...v,
                                          grade: surveyConfigs[parseInt(selectedProfile)].frontal[i]
                                        }))}/>
                                    <MemoryCategory
                                        title={translate('Parietal / Temporal')}
                                        patientProfile={parietal}
                                        score={parietalCurrent}
                                        totalScore={parietalTotalNormalized}
                                        illnessProfile={ptCategory.map((v, i) => ({
                                          ...v,
                                          grade: surveyConfigs[parseInt(selectedProfile)].temporal[i]
                                        }))}
                                        profileTitle={surveyConfigs[parseInt(selectedProfile)].title}/>
                                    <MemoryCategory
                                        title={translate('Subcortical')}
                                        patientProfile={subcortical}
                                        score={subcorticalCurrent}
                                        totalScore={subcorticalTotalNormalized}
                                        illnessProfile={skCategory.map((v, i) => ({
                                          ...v,
                                          grade: surveyConfigs[parseInt(selectedProfile)].subcortical[i]
                                        }))}
                                        profileTitle={surveyConfigs[parseInt(selectedProfile)].title}/>
                                    <MemoryCategory
                                        title={translate('Memory')}
                                        patientProfile={memory}
                                        score={memoryCurrent}
                                        totalScore={memoryTotalNormalized}
                                        illnessProfile={mCategory.map((v, i) => ({
                                          ...v,
                                          grade: surveyConfigs[parseInt(selectedProfile)].memory[i]
                                        }))}
                                        profileTitle={surveyConfigs[parseInt(selectedProfile)].title}/>
                                    <MemoryCategory
                                        title={translate('Associated symptoms')}
                                        patientProfile={associatedSymptoms}
                                        score={associatedSymptomsCurrent}
                                        totalScore={associatedSymptomsTotalNormalized}
                                        illnessProfile={asCategory.map((v, i) => ({
                                          ...v,
                                          grade: surveyConfigs[parseInt(selectedProfile)].associatedSymptoms[i]
                                        }))}
                                        profileTitle={surveyConfigs[parseInt(selectedProfile)].title}/>
                                    <QuestionCard
                                        patientName={patientName}
                                        questions={questions}
                                        answers={surveyAnswers}/>
                                </div>
                            </div>
                            <p style={{fontSize: '10px'}}>
                                1. Åstrand R, Rolstad S, Wallin A. Cognitive Impairment Questionnaire (CIMP-QUEST):
                                reported topographic symptoms in MCI and dementia. Acta Neurol Scand. 2010:121:384-391.
                                <br/>
                                2. Åstrand R. Neurokognitiv Symtomenkät: Hjärnregionalt orienterad och grafiskt
                                vägledande presentation av rapporterade symtom vid diagnostik av demenssjukdom. Svensk
                                Geriatrik. 2015;2:22-6.
                                <br/>
                                <a rel="noopener noreferrer" target="_blank"
                                   href="https://www.minnesmottagningen.se/forskning-evidens">www.gerassolutions.com</a>
                            </p>
                        </section>
                    </div>
                }
              </div>
            )
          }
        </Toggleable>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  // @ts-ignore
  surveyConfigs: getBrainConfig(state),
})

const mapDispatchToProps = (dispatch: (action: ModalWindowDataType) => void) => ({
  openModal: (type: ModalWindowType, data: any): void => dispatch(openModalWindow(type, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FamilySurveyCard)
