/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTrackingEventDto } from '../models/CreateTrackingEventDto';
import type { TrackingEvent } from '../models/TrackingEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TrackingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Track an event
     * @param requestBody
     * @returns TrackingEvent
     * @throws ApiError
     */
    public trackEvent(
        requestBody: CreateTrackingEventDto,
    ): CancelablePromise<TrackingEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all tracking events
     * @param eventType
     * @returns TrackingEvent
     * @throws ApiError
     */
    public findAll(
        eventType: string,
    ): CancelablePromise<Array<TrackingEvent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tracking',
            query: {
                'eventType': eventType,
            },
        });
    }

}
