import React, { useEffect, useState } from "react"
import { translate } from "../../selectors/translations"
import { formatToDate } from "../../utils/datetime"
import {
  calculateActualMaxSymptomSurveyScore,
  calculateSymptomSurveyScore
} from "../../utils/calculateSymptomSurveyScore"
import { getAnswerClass } from "../../utils/getAnswerClass"
import { ReactComponent as BrainTop } from "../../assets/img/brain-top.svg"
import QuestionAnswerComponent from "./questionAnswerComponent"
import { useSelector } from "react-redux"
import {
  asCategory,
  fCategory,
  getBrainConfig,
  mCategory,
  ptCategory,
  skCategory,
  symptomSurveyScoreConfigs
} from "../../selectors/survey"
import { base64BrainImg, SymptomSurveyPdfOptions } from "../../types/constants"
import {
  getAnswersForSection,
  getDementiaDuration,
  getSymptomsDevelopChartPicture
} from "../../components/FamilySurveyCard/FamilySurveyCard"
import { PdfSelectedItem } from "./generatePdf"
import { InputType, QuestionerAnswersDTOOutput, QuestionerDTO, UserDto } from "../../sdk"
import { isQuestionScreen } from "../../sdk/asserts"
import classNames from "classnames"
import FamilyProgressLine from "../../components/FamilySurveyCard/FamilyProgressLine"

type SymptomSurveyPdfProps = {
  survey: QuestionerAnswersDTOOutput;
  patient: Pick<UserDto, 'firstName' | 'lastName'>;
  selectedLanguage: string;
  selectedSurvey: PdfSelectedItem;
  oldStyle: boolean;
}

const findScreenBySlug = (questioner: QuestionerDTO, slug: string) => questioner.sections[5].screens.find(e => e.screen.slug === slug)?.screen
export const getRelativeDegree = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput) => {
  const relativeQuestion = findScreenBySlug(questioner, 'how-related-with-patienteny')
  return answers.answers.find(e => e.screen === relativeQuestion?.id)?.value
}

const SymptomSurveyPdfSection: React.FC<SymptomSurveyPdfProps> = (props: SymptomSurveyPdfProps) => {
  const {survey, selectedSurvey, patient, oldStyle} = props
  const patientName = oldStyle ? 'Patient' : patient.firstName + ' ' + patient.lastName
  const sectionsVisible = new Set(selectedSurvey.sectionsVisible)
  const surveyConfigs = useSelector(getBrainConfig)
  const [base64Image, setBase64Image] = useState('')
  const parietal = getAnswersForSection(survey.questioner, survey, 0)
  const frontal = getAnswersForSection(survey.questioner, survey, 1)
  const subcortical = getAnswersForSection(survey.questioner, survey, 2)
  const memory = getAnswersForSection(survey.questioner, survey, 3)
  const associatedSymptoms = getAnswersForSection(survey.questioner, survey, 4)

  const symptomsManifestationsAnswer = getSymptomsDevelopChartPicture(survey.questioner, survey)
  const {dementiaDuration, dementiaAnswerNotRelevant} = getDementiaDuration(survey.questioner, survey)

  const getBase64Image = (img: HTMLImageElement) => {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')!
    ctx.drawImage(img, 0, 0)
    return canvas.toDataURL('image/png')
  }

  useEffect(() => {
    const image = new Image()
    image.src = symptomsManifestationsAnswer
    image.onload = () => {
      const base64 = getBase64Image(image).replaceAll('image/png', 'image//png')
      setBase64Image(base64)
    }
  }, [symptomsManifestationsAnswer])


  const freeTextEnabled = sectionsVisible.has(SymptomSurveyPdfOptions.AllFreeTextAnswers)
  const selectorsEnabled = sectionsVisible.has(SymptomSurveyPdfOptions.AllMultipleChoices)
  const allQuestions = survey.questioner.sections
    .flatMap(e => e.screens)
    .map(e => e.screen)
    .filter(isQuestionScreen)

  const inputAnswers =
    allQuestions
      .filter((screen) => {
        if (screen?.metadata.input.kind === InputType.FREE_TEXT && freeTextEnabled) {
          return true
        }
        return (screen?.metadata.input.kind === InputType.RADIO_GROUP || screen?.metadata.input.kind === InputType.SELECTOR)
          && selectorsEnabled
      })
      .filter(e => {
        if (oldStyle) {
          return [
            'symptoms-variations-day-to-day',
            'first-symptoms-noticed',
            'most-noticeable-symptoms',
            'hard-to-manage-symptoms',
            'evident-specific-event-symptoms',
            'stress-for-the-family',
            'patienten-agree-with-symptom-survey',
            'space-for-comments'
          ].includes(e.slug)
        }

        return true
      })
      .map((screen, index) => {
        const answer = survey.answers.find(e => e.screen === screen.id)

        return {
          number: index! + 1,
          screen: screen!,
          answer: answer || {value: ''},
        }
      })

  const diagnostics = [
    {
      answers: frontal,
      illness: fCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].frontal[i]}))
    },
    {
      answers: parietal,
      illness: ptCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].temporal[i]}))
    },
    {
      answers: subcortical,
      illness: skCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].subcortical[i]}))
    },
    {
      answers: memory,
      illness: mCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].memory[i]}))
    },
    {
      answers: associatedSymptoms,
      illness: asCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].associatedSymptoms[i]}))
    },
  ]
  const maxScore = calculateActualMaxSymptomSurveyScore([
    ...parietal,
    ...frontal,
    ...subcortical,
    ...memory,
    ...associatedSymptoms,
  ])
  const maxScorePossible = symptomSurveyScoreConfigs.maxScoreAnswerValue * (symptomSurveyScoreConfigs.endIndex + 1)
  const currentScore = calculateSymptomSurveyScore([
    ...parietal,
    ...frontal,
    ...subcortical,
    ...memory,
    ...associatedSymptoms,
  ])

  return <>
    <h1
      className="header2 margin-top pb-10 break_before--page">{translate('pdf.symptomSurvey')} (CIMP-QUEST**) {formatToDate(survey.updatedAt!)}</h1>
    <div className="diagnostic_test_body">
      <div className="survey-test-top-content">
        <section className="test_general-info_box">
          <div className="flex justify-between">
            <div className="inline-block" hidden={oldStyle}>
              <div className="common-title">{translate('Answered by')}</div>
              <div className="info-body">{`${survey.oneTimeRespondent?.firstName ?? ''}
                           ${survey.oneTimeRespondent?.lastName ?? ''}`}</div>
            </div>

            <div className="inline-block" hidden={oldStyle}>
              <div className="common-title">{translate('Relationship')}</div>
              <div className="info-body">{translate(getRelativeDegree(survey.questioner, survey))}</div>
            </div>

            <div className="inline-block">
              <div className="common-title">{translate('Date answered')}</div>
              <div className="info-body">{formatToDate(survey.createdAt!)}</div>
            </div>

            <div className="inline-block" hidden={oldStyle}>
              <div className="common-title">{translate('phone')}:</div>
              <div className="info-body">{survey.oneTimeRespondent?.phoneNumber || '-'}</div>
            </div>
          </div>
        </section>

        <section className="preliminary-results_box">
          {/*<div className="test_description_title">{translate('report-header')}</div>*/}
          {/*<div className="preliminary-results-wrap">*/}
          <div className="flex justify-between">
            <div className="inline-block">
              <div className="common-title">{translate('Duration in years')}</div>
              {!dementiaAnswerNotRelevant &&
                  <div className="dementia-duration_box">
                      <ul className="dementia_sections-list">
                        {dementiaDuration.map((item, index) => {
                          return <li key={`dementia-${index}`} className={item}>&nbsp;</li>
                        })
                        }
                      </ul>
                      <ul className="dementia_values-list">
                          <li>&lt;1/2</li>
                          <li>1/2-1</li>
                          <li>1-2</li>
                          <li>2-3</li>
                          <li>3-5</li>
                          <li>&gt;5</li>
                      </ul>
                  </div>
              }
              {dementiaAnswerNotRelevant &&
                  <div className="dementia-duration_box">
                      <p className="">{translate('answered_as_not_relevant')}</p>
                  </div>
              }
              {
                <div style={{marginTop: 10}}>
                  <FamilyProgressLine
                    infoLegendKey={'symptom_survey.riskLegend.hint'}
                    legend={`${translate('Points')}: <strong>${currentScore}</strong> / <strong>${maxScore}</strong> (${translate('max_if_all_answered')}. ${maxScorePossible})`}
                    progressValue={currentScore}
                    maxIntervalValue={maxScore}
                    noPrint={true}
                  />
                </div>
              }
            </div>
            <div className="inline-block">
              <div className="common-title">{translate('Total points')}</div>
              <ul className="commot-list">
                <li><strong>{calculateSymptomSurveyScore(frontal)}</strong>&nbsp;{translate('Frontal')}</li>
                <li><strong>{calculateSymptomSurveyScore(parietal)}</strong>&nbsp;{translate('Parietal / Temporal')}
                </li>
                <li><strong>{calculateSymptomSurveyScore(subcortical)}</strong>&nbsp;{translate('Subcortical')}</li>
                <li><strong>{calculateSymptomSurveyScore(memory)}</strong>&nbsp;{translate('Memory')}</li>
                <li>
                  <strong>{calculateSymptomSurveyScore(associatedSymptoms)}</strong>&nbsp;{translate('Associated symptoms')}
                </li>
              </ul>
            </div>
            <div className="inline-block">
              <div className="course-сhart_wrapper">
                <div className="common-title">{translate('Course')}</div>
                <img className="courseChart" src={base64Image} alt="chart-picture"/>
              </div>
            </div>
          </div>
        </section>
      </div>

      {sectionsVisible.has(SymptomSurveyPdfOptions.SurveyPage) &&
          <>
              <section>
                  <div className="flex-container disfunctions_analize">
                      <div className="brains-analize-wrapper">
                          <div className="brain_map_box">
                              <div className="brain_layer brain_layer--profil">
                                  <div className="additional-wrapper">
                                      <img className="BrainProfil" src={base64BrainImg}/>
                                      <div className="brain_points-list">
                                        {frontal.map((a, i) => (
                                          <div className={`brain_point-item f${i} ${getAnswerClass(a.value, oldStyle)}`}
                                               key={`f${i}`} data-content={translate(fCategory[i].title)}>
                                            <span>{`F${i + 1}`}</span>
                                          </div>
                                        ))}
                                        {parietal.map((a, i) => (
                                          <div
                                            className={`brain_point-item pt${i} ${getAnswerClass(a.value, oldStyle)}`}
                                            key={`pt${i}`} data-content={translate(ptCategory[i].title)}>
                                            <span>{`PT${i + 1}`}</span>
                                          </div>
                                        ))}
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="brain_map_box">
                              <div className="brain_layer brain_layer--top">
                                  <BrainTop/>
                                  <ul className="brain_points-list">
                                    {subcortical.map((a, i) => (
                                      <div className={`brain_point-item sk${i} ${getAnswerClass(a.value, oldStyle)}`}
                                           key={i}
                                           data-content={translate(skCategory[i].title)}>
                                        <span>{`SK${i + 1}`}</span>
                                      </div>
                                    ))}
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>

              <div className="spoiler_body brain_graph_analize">
                  <div className="survey-diagnostic-header">
                      <span>{translate('Frontal')}</span>
                      <span>{translate('Parietal / Temporal')}</span>
                      <span>{translate('Subcortical')}</span>
                      <span>{translate('Memory')}</span>
                      <span>{translate('Associated symptoms')}</span>
                  </div>
                  <div className="flex-container diagnostic_question_body">
                      <div className="survey-diagnostic-wrapper">
                        {
                          diagnostics.map((diagnostic, index) => <ul className="survey-diagnostic-list" key={index}>
                            {diagnostic.illness.map((item, i) => (
                              <li key={i}>
                                <div
                                  className={`brain-zone_color ${getAnswerClass(diagnostic.answers[i].value, oldStyle)}`}>{item.type}</div>
                                <p className="m-0 brain-zone_title">{translate(item.title)}</p>
                              </li>
                            ))}
                          </ul>)
                        }
                      </div>
                  </div>
              </div>
              <div className="disfunctions_legend">
                  <div className="flex-item flex-item--shrink">
                      <div
                          className={classNames(['legend-item', {'red': !oldStyle, 'marker-color-red-old': oldStyle}])}>
                          <span>{translate('p-1')}</span></div>
                  </div>
                  <div className="flex-item flex-item--shrink">
                      <div className={classNames(['legend-item', {
                        'yellow': !oldStyle,
                        'marker-color-yellow-old': oldStyle
                      }])}><span>{translate('p-2')}</span></div>
                  </div>
                  <div className="flex-item flex-item--shrink">
                      <div className={classNames(['legend-item', {
                        'green': !oldStyle,
                        'marker-color-green-old': oldStyle
                      }])}><span>{translate('p-3')}</span></div>
                  </div>
                  <div className="flex-item flex-item--shrink">
                      <div className="legend-item white"><span>{translate('p-4')}</span></div>
                  </div>
              </div>
          </>
      }
      {
        inputAnswers ? inputAnswers.map((el) => <QuestionAnswerComponent
          key={el.screen.id}
          patientName={patientName}
          question={el.screen}
          questionNumber={el.number}
          answer={el.answer}
        />) : null
      }
      <p style={{fontSize: '9px'}}>
        **Åstrand R, Rolstad S, Wallin A. Cognitive Impairment Questionnaire (CIMP-QUEST): reported topographic
        symptoms in MCI and dementia. Acta Neurol Scand. 2010:121:384-391.
        <br/>
        Åstrand R. Neurokognitiv Symtomenkät: Hjärnregionalt orienterad och grafiskt vägledande presentation av
        rapporterade symtom vid diagnostik av demenssjukdom. Svensk Geriatrik.
        <br/>
        2015;2:22-6.<a rel="noopener noreferrer" target="_blank"
                       href="https://www.minnesmottagningen.se/forskning-evidens">www.gerassolutions.com</a>
      </p>
    </div>
  </>
}

export default SymptomSurveyPdfSection
