import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrackingEventsRequest } from '../../store/TrackingEvents/actions';
import { TrackingEventsState } from '../../store/TrackingEvents/types';
import { ApplicationState } from '../../store';
import { translate } from '../../selectors/translations';

const TrackingEvents: React.FC = () => {
  const dispatch = useDispatch();
  const { events, loading, error } = useSelector<ApplicationState, TrackingEventsState>(
    (state) => state.trackingEvents
  );

  useEffect(() => {
    dispatch(fetchTrackingEventsRequest());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex w-100 h-100 justify-center align-center">
        <p className="m-0">{translate('Loading...')}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card-box">
        <div className="alert alert-danger">
          {translate('Error')}: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="card-box">
      <h2>{translate('Tracking Events')}</h2>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className="text-left">
              <th>{translate('ID')}</th>
              <th>{translate('Event Type')}</th>
              <th>{translate('Created At')}</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event._id}>
                <td>{event._id}</td>
                <td>{event.eventType}</td>
                <td>{new Date(event.createdAt).toLocaleString()}</td>
              </tr>
            ))}
            {events.length === 0 && (
              <tr>
                <td colSpan={3} className="text-center">
                  {translate('No events found')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrackingEvents; 