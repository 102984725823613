import { call, put, takeLatest } from 'redux-saga/effects';
import { TrackingEventsActionTypes } from './types';
import { fetchTrackingEventsSuccess, fetchTrackingEventsFailure } from './actions';
import { apiV2, attachTokenToRequests } from '../../repositories/repository';

function* fetchTrackingEventsSaga(action: any) {
  try {
    attachTokenToRequests();
    const eventType = action.payload ?? '';
    const response = yield call([apiV2.tracking, 'findAll'], eventType);
    yield put(fetchTrackingEventsSuccess(response));
  } catch (error: any) {
    console.error('Error fetching tracking events:', error);
    yield put(fetchTrackingEventsFailure(error.message || 'An error occurred'));
  }
}

export function* trackingEventsSaga() {
  yield takeLatest(TrackingEventsActionTypes.FETCH_TRACKING_EVENTS_REQUEST, fetchTrackingEventsSaga);
} 