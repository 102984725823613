import {
  TrackingEventsActionTypes,
  FetchTrackingEventsSuccessAction,
  FetchTrackingEventsFailureAction,
  TrackingEvent,
} from './types';
import { TrackingEventType } from "../../sdk";

export const fetchTrackingEventsRequest = (eventType?: TrackingEventType) => ({
  type: TrackingEventsActionTypes.FETCH_TRACKING_EVENTS_REQUEST,
  payload: eventType,
});

export const fetchTrackingEventsSuccess = (events: TrackingEvent[]): FetchTrackingEventsSuccessAction => ({
  type: TrackingEventsActionTypes.FETCH_TRACKING_EVENTS_SUCCESS,
  payload: events,
});

export const fetchTrackingEventsFailure = (error: string): FetchTrackingEventsFailureAction => ({
  type: TrackingEventsActionTypes.FETCH_TRACKING_EVENTS_FAILURE,
  payload: error,
}); 