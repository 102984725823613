import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search-icon.svg'

import {
  PatientFilters,
  FilterChangedData,
  PatientsActionType,
  PatientAction
} from '../../store/Patients/types'
import { getDeletedPatients, filterChanged, resetPatientsState } from '../../store/Patients/action'

import { ApplicationState } from '../../store'

import { getLanguages, translate } from '../../selectors/translations'
import { LanguageType } from '../../store/Translations/types'
import { openModalWindow } from '../../store/ModalWindowData/action'
import { ModalWindowDataType } from '../../store/ModalWindowData/types'
import { getDeletedPatients as getDeletedPatientsSelector } from '../../selectors/patients'
import { PatientTableBody, TableHeaders } from '../../components/DataTable/Table'
import { unsetPatientProfile } from "../../store/PatientProfile/action"
import { PatientProfileActionType } from "../../store/PatientProfile/types"
import greenHills from '../../assets/img/greenHills.svg'
import { InstitutionAccess, UserDto } from "../../sdk"
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"
import { institutionAccess } from "../../selectors/institutions"

type PropsFromState = {
  deletedPatients: UserDto[];
  filters: PatientFilters;
  successMsg?: string;
  errorMsg?: string;
  access: InstitutionAccess;
  languages: { key: LanguageType; inUse: boolean }[];
}

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>

type AllProps = PropsFromDispatch & PropsFromState & RouteComponentProps;

const tableTitles = [
  'lastName',
  'firstName',
  'personalNumber',
  'age',
  'gender',
  'createdAt'
]

class PatientDeletedListPage extends React.Component<AllProps> {
  actionTypes: { [key: string]: (data: string) => void } = {
    'restore': (): void => {
    },
    'delete': (): void => {
    },
    'card': (): void => {
    }
  }

  constructor(props: AllProps) {
    super(props)

    this.actionTypes.restore = ((id: string): void => {
      const patient = this.props.deletedPatients.find((p) => p._id === id)!
      this.props.openModal('restore', {
        _id: patient._id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        type: 'restorePatient'
      })
    }).bind(this)

    this.actionTypes.delete = ((id: string): void => {
      const patient = this.props.deletedPatients.find((p) => p._id === id)!
      this.props.openModal('delete', {
        _id: patient._id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        type: 'deletePatient'
      })
    }).bind(this)
  }

  public componentDidMount(): void {
    this.props.resetPatientProfile()
    this.props.getData()
  }

  componentWillUnmount(): void {
    this.props.resetState()
  }

  render(): React.ReactElement {
    const canDelete = this.props.access.deletingPatients
    const {deletedPatients, filters, filterChanged} = this.props
    const resultsText = (deletedPatients && deletedPatients.length === 1) ? translate('deletedPatients.counter.singular') : translate('deletedPatients.counter.plural')
    const availableActions = ['restore']
    if (canDelete) {
      availableActions.push('delete')
    }
    return (
      <div>
        <div className="search-box">
          <div className="flex-container justify-between align-start">
            <div className="flex-item item-sm-12 item-lg-7">
              <div className="search-block_wrapper flex">
                <div className="input-holder flex-item--stretch">
                  <div className="icon-holder">
                    <SearchIcon className="search-block_search-icon"/>
                  </div>
                  <input
                    className="search-block_input"
                    onChange={(e): void => filterChanged({searchTerm: e.target.value})}
                    type="text"
                    value={filters.searchTerm}
                    placeholder={translate('Write patient’s name, personal number, or email')}/>
                </div>
              </div>
              <div className="search-block_results-counter hidden-lg-down">
                <span>{translate('search.results')}:&nbsp;</span>
                <span className="t_bold">{deletedPatients.length || 0}&nbsp;</span>
                <span>{resultsText}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="search_table-box">
          <table className="search_patients-table">

            <TableHeaders
              onSortClick={filterChanged}
              sortOpts={{
                sortableColumns: ['lastName', 'firstName', 'createdAt', 'gender', 'age'],
                isAsc: filters.sort.direction,
                filterField: filters.sort.field
              }}
              columnTitles={tableTitles}
            />
            <PatientTableBody
              data={deletedPatients}
              actions={availableActions}
              propKeys={tableTitles}
              onActionClick={(data: PatientAction): void | ReactElement => this.actionTypes[data.action](data._id)}
            />
          </table>
        </div>
        <img className="greenHills" src={greenHills}/>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  deletedPatients: getDeletedPatientsSelector(state),
  access: institutionAccess(state),
  filters: state.patients.filters,
  successMsg: '',
  errorMsg: '',
  languages: getLanguages(state)
})

const mapDispatchToProps = (dispatch: (action: PatientsActionType | ModalWindowDataType | PatientProfileActionType) => void) => ({
  getData: (): void => dispatch(getDeletedPatients()),
  filterChanged: (data: FilterChangedData): void => dispatch(filterChanged(data)),
  openModal: (type: ModalWindowType, data: any, cb?: () => void): void => dispatch(openModalWindow(type, data, cb)),
  resetState: (): void => dispatch(resetPatientsState()),
  resetPatientProfile: (): void => dispatch(unsetPatientProfile())
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientDeletedListPage)
