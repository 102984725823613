import { SsAnswerVariant } from "./calculateSymptomSurveyScore"

export const getAnswerClass = (answerData: string, old = false) => {
  return `color-${normalizeAnswerValue2(answerData as SsAnswerVariant)}` + (old ? '-old' : '')
}

export const normalizeAnswerValue2 = (answerValue: SsAnswerVariant) => {
  switch (answerValue) {
    case SsAnswerVariant.idk:
      return 'white'
    case SsAnswerVariant.no:
      return 'green'
    case SsAnswerVariant.maybe:
      return 'yellow'
    case SsAnswerVariant.yes:
      return 'red'
  }

}
