import React, { useCallback } from "react"
import classNames from "classnames"
import './MedicalTestItem.scss'
import useTranslate from "../../../../hooks/useTranslate"

interface IProps {
  id: string | number;
  isSelected: boolean;
  available: boolean;
  title: string;
  onClick: (id: any) => void;
}

export const MedicalTestItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {id, isSelected, title, available, onClick} = props
  const translateLocalazy = useTranslate()

  const handleClick = useCallback(() => {
    if (available) {
      onClick && onClick(id)
    }
  }, [onClick, id])

  const className = classNames([
    'medical-test-item',
    {
      'medical-test-item--selected': isSelected && available,
      'feature-blocked': !available
    }
  ])

  return (
    <div className="medical-test-item__wrapper" title={available ? undefined : translateLocalazy('feature-blocked')}>
      <div className={className}
           onClick={handleClick}>{title}</div>
    </div>
  )
}

export default MedicalTestItem
