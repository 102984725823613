import { InstitutionsState, InstitutionsActionType, InstitutionEnum } from './types'

const initialState: InstitutionsState = {
  all: [],
  statistic: [],
  errorMsg: ''
}

export function InstitutionsReducer(
  state = initialState,
  action: InstitutionsActionType
): InstitutionsState {
  switch (action.type) {
    case InstitutionEnum.UPDATE_INSTITUTION_REQUEST:
    case InstitutionEnum.CREATE_INSTITUTION_REQUEST:
    case InstitutionEnum.GET_INSTITUTIONS_STATISTIC_REQUEST:
    case InstitutionEnum.GET_INSTITUTIONS_REQUEST:
      return {...state}
    case InstitutionEnum.GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        all: action.payload
      }
    case InstitutionEnum.GET_INSTITUTIONS_STATISTIC_SUCCESS:
      return {
        ...state,
        statistic: action.payload
      }
    case InstitutionEnum.CREATE_INSTITUTION_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.payload]
      }
    case InstitutionEnum.UPDATE_INSTITUTION_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        all: state.all.map(e => e.id === action.payload.id ? action.payload : e)
      }
    default:
      return state
  }
}

