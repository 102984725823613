import { all, call, put, takeLatest } from 'redux-saga/effects'
import callApi from '../../utils/apiCaller';
import {
  AuthActionTypes,
  LoginRequest,
  PostSessionInfoRequest,
  PostAppVersionRequest,
  GetQrCodeDataRequest, CheckBankIdStatusRequest
} from './types'
import {
  loginSuccess,
  loginFail,
  sessionSuccess,
  sessionFailure,
  getReferrerFailure,
  getReferrerSuccess,
  setAppVersionSuccess,
  setAppVersionFailure,
  setErrorMsg,
  startBankIdFail,
  startBankIdSuccess,
  getQrCodeDataSuccess,
  getQrCodeDataFail,
  checkBankIdStatusSuccess,
  checkBankIdStatusFail
} from './action'
import { ApiMethods, ApiAuthPath} from '../../types/Api';
import {getInstitutions} from "../Institutions/action";
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"
import { ProjectSettingName, Referrer } from "../../sdk"
import { getSmsTemplates } from "../SmsTemplate/action"

function* handleGetQrCodeDataRequest(data: GetQrCodeDataRequest): Generator<any> {
  const bankIdQrCodeData = apiV2.auth.getBankIdQrCodeData.bind(apiV2.auth)
  const qrCodeResult = (yield wrapResponse(bankIdQrCodeData(data.orderRef))) as SdkResponse<typeof bankIdQrCodeData>
  if (qrCodeResult.success) {
    yield put(getQrCodeDataSuccess(qrCodeResult.data))
  } else {
    yield put(getQrCodeDataFail(qrCodeResult.error))
    yield put(setErrorMsg(qrCodeResult.error))
  }
}

function* handleCheckBankIdRequest(data: CheckBankIdStatusRequest): Generator<any> {
  const bankIdQrCodeData = apiV2.auth.bankIdStatus.bind(apiV2.auth)
  const statusResult = (yield wrapResponse(bankIdQrCodeData(data.orderRef))) as SdkResponse<typeof bankIdQrCodeData>

  if (statusResult.success) {
    yield put(checkBankIdStatusSuccess(statusResult.data))
  } else {
    yield put(checkBankIdStatusFail(statusResult.error))
    yield put(setErrorMsg(statusResult.error))
  }
}

function* handleStartBankIdRequest(): Generator<any> {
  const startBankIdAuth = apiV2.auth.startBankIdAuth.bind(apiV2.auth)
  const startResult = (yield wrapResponse(startBankIdAuth({referrer: Referrer.HCP}))) as SdkResponse<typeof startBankIdAuth>

  if (startResult.success) {
    yield put(startBankIdSuccess(startResult.data))
  } else {
    yield put(startBankIdFail(startResult.error))
    yield put(setErrorMsg(startResult.error))
  }
}

function* handleLoginRequest(opts: LoginRequest): Generator<any> {
  const apiCall = apiV2.auth.loginWithPassword.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(opts.data))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(loginSuccess(res.data))
  } else {
    yield put(loginFail('Access denied'))
    yield put(setErrorMsg('Access denied'))
  }
}

function* handleSessionRequest(): Generator<any> {
  const apiCall = apiV2.auth.getSession.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>

  yield put(getInstitutions())
  yield put(getSmsTemplates())

  if (res.success) {
    yield put(sessionSuccess(res.data))
  } else {
    yield put(sessionFailure(res.error))
  }
}

function* handleSessionInfoRequest(opts: PostSessionInfoRequest): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.POST, ApiAuthPath.SESSION_INFO, opts.payload);
    if (res.error) {
      yield put(getReferrerFailure(res.error));
    } else {
      yield put(getReferrerSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getReferrerFailure(err.stack))
    } else {
      yield put(getReferrerFailure('An unknown error occured.'))
    }
  }
}

function* handlePostAppVersionRequest(opts: PostAppVersionRequest): Generator {
  const apiCall = apiV2.projectSetting.upsertAppVersion.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall({
    name: ProjectSettingName.APP_VERSION,
    value: opts.payload
  }))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(setAppVersionSuccess(res.data))
  } else {
    yield put(setAppVersionFailure(res.error))
  }
}

function* authSaga(): Generator {
  yield all([
    takeLatest(AuthActionTypes.POST_APP_VERSION_REQUEST, handlePostAppVersionRequest),
    takeLatest(AuthActionTypes.POST_LOGIN_REQUEST, handleLoginRequest),
    takeLatest(AuthActionTypes.GET_SESSION_REQUEST, handleSessionRequest),
    takeLatest(AuthActionTypes.POST_SESSION_INFO_REQUEST, handleSessionInfoRequest),
    takeLatest(AuthActionTypes.START_BANK_ID_REQUEST, handleStartBankIdRequest),
    takeLatest(AuthActionTypes.GET_QR_CODE_DATA_REQUEST, handleGetQrCodeDataRequest),
    takeLatest(AuthActionTypes.CHECK_BANK_ID_STATUS_REQUEST, handleCheckBankIdRequest)
  ])
}

export default authSaga;
