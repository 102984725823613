import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close-icon.svg'
import { translate } from '../../selectors/translations'
import { ModalWindowAction } from '../../store/ModalWindowData/types'
import 'react-phone-input-2/lib/plain.css'
import './PhoneConfirmation.scss'
import PhoneInputWithMask from "../PhoneInputWithMask"
import { UserDto } from "../../sdk"
import useTranslate from "../../hooks/useTranslate"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { institutionAccess } from "../../selectors/institutions"

type PropsFromState = {
  onConfirm: (data: ModalWindowAction<any>) => void;
  data: { patient: UserDto };
}

type FormActionState = {
  onConfirm: (val: any) => void;
  data?: any;
  onSubmitAction?: string;
  btnDisabled?: boolean;
}

const FormHeader = (props: FormActionState): React.ReactElement => {
  const {onConfirm} = props
  return (
    <div className="note-popup_header flex collapse justify-betweenalign-center">
      <div className="flex-item flex-item--stretch">{translate('Send symptom survey to relative')}</div>
      <div className="flex-item flex-item--shrink">
        <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
        </button>
      </div>
    </div>
  )
}

const FormFooter = (props: FormActionState): React.ReactElement => {
  const {onConfirm, data, onSubmitAction, btnDisabled} = props
  const translateLocalazy = useTranslate()
  const access = useSelector(institutionAccess)
  const available = access.sms

  const className = classNames([
    'g_btn g_btn--primary',
    {
      'feature-blocked': !available,
      'g_btn--disabled': btnDisabled,
    }
  ])
  return (
    <div className="note-popup_footer">
      <button
        title={available ? undefined : translateLocalazy('feature-blocked')}
        className={className} disabled={btnDisabled || !available}
        onClick={(): void => onConfirm({action: onSubmitAction, data})}>{translate('send')}</button>
      <button className="g_btn g_btn--outlined"
              onClick={(): void => onConfirm({action: 'cancel', data: null})}>{translate('cancel')}</button>
    </div>
  )
}

export const RequestFamilySurveyForm = (props: PropsFromState) => {
  const {data, onConfirm} = props
  const patientId = data.patient._id
  const institution = data.patient.institution
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isValid, setValid] = useState(false)

  const handleChange = (phone: string, data: any): void => {
    const rawNumber = phone.slice(data.dialCode.length)
    if (rawNumber.length && rawNumber[0] === '0') {
      setPhoneNumber(`${data.dialCode}${rawNumber.slice(1)}`)
      return
    }
    setPhoneNumber(phone)
    setValid(phone.length >= 9)
  }

  return (
    <div>
      <FormHeader onConfirm={(val): void => onConfirm({action: val.action, data: {}})}/>

      <div className="input-holder flex-item item-sm-8" style={{marginTop: '14px'}}>
        <label>{translate('EnterRelativePhoneNumber')}</label>
        <PhoneInputWithMask
          institution={institution}
          inputValue={phoneNumber}
          onChange={handleChange}/>
      </div>
      <FormFooter onSubmitAction={'requestFamilySurvey'}
                  btnDisabled={!isValid}
                  onConfirm={(val): void => onConfirm({
                    action: val.action,
                    data: {phoneNumber: `+${phoneNumber}`, patientId}
                  })}
      />
    </div>
  )
}
