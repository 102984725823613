import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"

import { openModalWindow } from "../../../store/ModalWindowData/action"
import useTranslate from "../../../hooks/useTranslate"
import {
  getInstitutionsSelector,
  getInstitutionStatisticSelector,
} from "../../../selectors/institutions"
import "./Institutions.scss"
import { getInstitutionStatistic } from "../../../store/Institutions/action"

const Institutions: React.FC<any> = () => {
  const dispatch = useDispatch()
  const translateLocalazy = useTranslate()
  const institutions = useSelector(getInstitutionsSelector)
  const institutionStatistic = useSelector(getInstitutionStatisticSelector)

  useEffect(() => {
    dispatch(getInstitutionStatistic())
  }, [])

  return (
    <div className="card-box patient-info">
      <div className="box-title">
        <button style={{marginLeft: '24px'}}
                onClick={() => dispatch(openModalWindow('createInstitution', null))}
                className="g_btn g_btn--primary"
                type="button">{translateLocalazy('admin.institution.add-new-btn')}
        </button>
      </div>
      <br/>
      <div className="voucher-code">
        <span className="statistic-item">{translateLocalazy('admin.institution.name')}</span>
        <span className="statistic-item">{translateLocalazy('admin.institution.sms-name')}</span>
        <span className="statistic-item">{translateLocalazy('admin.institution.old-ss-pdf')}</span>
        <span className="statistic-item">{translateLocalazy('admin.institution.access')}</span>
        <span className="statistic-item">{translateLocalazy('admin.institution.people-amount')}</span>
        <span className="statistic-item"></span>
      </div>
      {
        institutions.map((institution, index) => {
          const statistic = institutionStatistic.find(e => e._id === institution.id)

          return (
            <div key={index} className="voucher-code ">
              <span className="statistic-item institution-name" style={{marginRight: '24px'}}>{institution.name}</span>
              <span className="statistic-item" style={{marginRight: '24px'}}>{institution.smsName}</span>
              <span className="statistic-item" style={{marginRight: '24px'}}>
                <div className="checkbox-wrapper-2">
                    <input type="checkbox" disabled checked={institution.oldStyleSsPdf}/>
                  </div>
              </span>
              <span className="statistic-item" style={{marginRight: '24px'}}>
                <div className="access-item">
                  <div className="checkbox-wrapper-2">
                    <input type="checkbox" disabled checked={institution.access.symptomSurveyTest}/>
                  </div>
                  Symptom survey
                </div>
                <div className="access-item">
                  <div className="checkbox-wrapper-2">
                    <input type="checkbox" disabled checked={institution.access.anamnesisTest}/>
                  </div>
                  Anamnesis
                </div>
                <div className="access-item">
                  <div className="checkbox-wrapper-2">
                    <input type="checkbox" disabled checked={institution.access.cognitiveTest}/>
                  </div>
                  Cognitive
                </div>
                <div className="access-item">
                  <div className="checkbox-wrapper-2">
                    <input type="checkbox" disabled checked={institution.access.sms}/>
                  </div>
                  Sms
                </div>
                <div className="access-item">
                  <div className="checkbox-wrapper-2">
                    <input type="checkbox" disabled checked={institution.access.deletingPatients}/>
                  </div>
                  Delete patients
                </div>
                <div className="access-item">
                  <div className="checkbox-wrapper-2">
                    <input type="checkbox" disabled checked={institution.access.medstaffManagement}/>
                  </div>
                  Medstaff management
                </div>
              </span>
              <span className="statistic-item">{statistic?.medstaffAmount || 0} / {statistic?.patientAmount || 0}</span>
              <span className="statistic-item">
                <button className="g_btn g_btn--outlined"
                        onClick={() => dispatch(openModalWindow('updateInstitution', institution))}
                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  Edit
                </button>
              </span>
            </div>
          )
        })
      }
    </div>
  )
}

export default Institutions
