import { FamilyState } from './Family/types';
import { combineReducers, Reducer } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { AppointmentsState } from './Appointments/types';
import { TranlsationsState } from './Translations/types';
import { PatientsState } from './Patients/types';
import { AccountsState } from './Accounts/types';
import { AuthState } from './Auth/types';
import { PatientProfileState } from './PatientProfile/types';
import { ModalWindowState } from './ModalWindowData/types';
import { VoucherCodesState } from './VoucherCodes/types';
import { InstitutionsState } from './Institutions/types';
import { QuestionersState } from "./Questioners/types";
import { TrackingEventsState } from './TrackingEvents/types';

import { TranslationReducer } from './Translations/reducers';
import { PatientReducer } from './Patients/reducer';
import { AccountReducer } from './Accounts/reducer';
import { AuthReducer } from './Auth/reducer';
import { AppointmentsReducer } from './Appointments/reducer'
import { FamilyReducer } from './Family/reducer';
import { PatientProfileReducer } from './PatientProfile/reducer';
import { ModalWindowDataReducer } from './ModalWindowData/reducer';
import { VoucherCodeReducer } from './VoucherCodes/reducer';
import { InstitutionsReducer } from './Institutions/reducer';
import { QuestionersReducer } from "./Questioners/reducers";
import { trackingEventsReducer } from './TrackingEvents/reducer';

import accountsSaga from './Accounts/sagas';
import familySaga from './Family/sagas';
import authSaga from './Auth/sagas';
import patientProfileSaga from './PatientProfile/sagas';
import appointmentsSaga from './Appointments/sagas'
import patientsSaga from './Patients/sagas';
import voucherCodes from './VoucherCodes/sagas';
import { AppReducer } from "./App/reducer";
import { AppState } from "./App/types";
import institutionsSaga from './Institutions/sagas';
import translationsSaga from './Translations/saga';
import questionersSaga from './Questioners/saga';
import smsTemplatesSaga from "./SmsTemplate/sagas"
import { SmsTemplatesReducer } from "./SmsTemplate/reducer"
import { SmsTemplatesState } from "./SmsTemplate/types"
import { trackingEventsSaga } from './TrackingEvents/saga';

export interface ApplicationState {
  translations: TranlsationsState;
  patients: PatientsState;
  accounts: AccountsState;
  auth: AuthState;
  patientProfile: PatientProfileState;
  family: FamilyState;
  appointments: AppointmentsState;
  modalWindowData: ModalWindowState;
  voucherCodes: VoucherCodesState;
  smsTemplates: SmsTemplatesState;
  appState: AppState;
  institutions: InstitutionsState;
  questioners: QuestionersState;
  trackingEvents: TrackingEventsState;
}

export const createRootReducer = (): Reducer<ApplicationState> => {
  return combineReducers({
    translations: TranslationReducer,
    patients: PatientReducer,
    patientProfile: PatientProfileReducer,
    accounts: AccountReducer,
    auth: AuthReducer,
    family: FamilyReducer,
    appointments: AppointmentsReducer,
    modalWindowData: ModalWindowDataReducer,
    voucherCodes: VoucherCodeReducer,
    smsTemplates: SmsTemplatesReducer,
    appState: AppReducer,
    institutions: InstitutionsReducer,
    questioners: QuestionersReducer,
    trackingEvents: trackingEventsReducer
  });
};

export function* rootSaga(): Generator {
  yield all([fork(patientsSaga)]);
  yield all([fork(accountsSaga)]);
  yield all([fork(authSaga)]);
  yield all([fork(familySaga)]);
  yield all([fork(patientProfileSaga)]);
  yield all([fork(appointmentsSaga)]);
  yield all([fork(voucherCodes)]);
  yield all([fork(smsTemplatesSaga)]);
  yield all([fork(institutionsSaga)]);
  yield all([fork(translationsSaga)]);
  yield all([fork(questionersSaga)]);
  yield all([fork(trackingEventsSaga)]);
}
