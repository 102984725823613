import { InstitutionDTO, InstitutionStatisticDTO, ManageInstitutionDTO } from "../../sdk"

export interface InstitutionsState {
  all: InstitutionDTO[];
  statistic: InstitutionStatisticDTO[];
  errorMsg: string;
}

export enum InstitutionEnum {
  GET_INSTITUTIONS_REQUEST = '@@institutions/GET_INSTITUTIONS_REQUEST',
  GET_INSTITUTIONS_SUCCESS = '@@institutions/GET_INSTITUTIONS_SUCCESS',

  GET_INSTITUTIONS_STATISTIC_REQUEST = '@@institutions/GET_INSTITUTIONS_STATISTIC_REQUEST',
  GET_INSTITUTIONS_STATISTIC_SUCCESS = '@@institutions/GET_INSTITUTIONS_STATISTIC_SUCCESS',

  CREATE_INSTITUTION_REQUEST = '@@institutions/CREATE_INSTITUTION_REQUEST',
  CREATE_INSTITUTION_SUCCESS = '@@institutions/CREATE_INSTITUTION_SUCCESS',

  UPDATE_INSTITUTION_REQUEST = '@@institutions/UPDATE_INSTITUTION_REQUEST',
  UPDATE_INSTITUTION_SUCCESS = '@@institutions/UPDATE_INSTITUTION_SUCCESS',
}

interface GetInstitutionRequest {
  type: typeof InstitutionEnum.GET_INSTITUTIONS_REQUEST;
}

interface GetInstitutionSuccess {
  type: typeof InstitutionEnum.GET_INSTITUTIONS_SUCCESS;
  payload: Array<InstitutionDTO>;
}

interface GetInstitutionStatisticRequest {
  type: typeof InstitutionEnum.GET_INSTITUTIONS_STATISTIC_REQUEST;
}

interface GetInstitutionStatisticSuccess {
  type: typeof InstitutionEnum.GET_INSTITUTIONS_STATISTIC_SUCCESS;
  payload: Array<InstitutionStatisticDTO>;
}

export interface CreateInstitutionRequest {
  type: typeof InstitutionEnum.CREATE_INSTITUTION_REQUEST;
  payload: ManageInstitutionDTO;
}

interface CreateInstitutionSuccess {
  type: typeof InstitutionEnum.CREATE_INSTITUTION_SUCCESS;
  payload: InstitutionDTO;
}

export interface UpdateInstitutionRequest {
  type: typeof InstitutionEnum.UPDATE_INSTITUTION_REQUEST;
  payload: ManageInstitutionDTO;
}

interface UpdateInstitutionSuccess {
  type: typeof InstitutionEnum.UPDATE_INSTITUTION_SUCCESS;
  payload: InstitutionDTO;
}

export type InstitutionsActionType =
  GetInstitutionRequest
  | GetInstitutionSuccess
  | GetInstitutionStatisticRequest
  | GetInstitutionStatisticSuccess
  | CreateInstitutionRequest
  | CreateInstitutionSuccess
  | UpdateInstitutionRequest
  | UpdateInstitutionSuccess
  ;
