import { TrackingEventType } from "../../sdk";

export interface TrackingEvent {
  _id: string;
  eventType: TrackingEventType;
  createdAt: string;
  metadata?: Record<string, any>;
}

export interface TrackingEventsState {
  events: TrackingEvent[];
  loading: boolean;
  error: string | null;
}

export enum TrackingEventsActionTypes {
  FETCH_TRACKING_EVENTS_REQUEST = 'FETCH_TRACKING_EVENTS_REQUEST',
  FETCH_TRACKING_EVENTS_SUCCESS = 'FETCH_TRACKING_EVENTS_SUCCESS',
  FETCH_TRACKING_EVENTS_FAILURE = 'FETCH_TRACKING_EVENTS_FAILURE',
}

export interface FetchTrackingEventsRequestAction {
  type: TrackingEventsActionTypes.FETCH_TRACKING_EVENTS_REQUEST;
  payload?: TrackingEventType;
}

export interface FetchTrackingEventsSuccessAction {
  type: TrackingEventsActionTypes.FETCH_TRACKING_EVENTS_SUCCESS;
  payload: TrackingEvent[];
}

export interface FetchTrackingEventsFailureAction {
  type: TrackingEventsActionTypes.FETCH_TRACKING_EVENTS_FAILURE;
  payload: string;
}

export type TrackingEventsAction =
  | FetchTrackingEventsRequestAction
  | FetchTrackingEventsSuccessAction
  | FetchTrackingEventsFailureAction; 